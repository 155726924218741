import React from 'react';
import * as S from './LockInSelectorV5.styles';

const BenefitsB = ({ currentCategory, setShowSubPopup }) => {
  return (
    <>
      <S.Row3>
        {'subscriptions' === currentCategory && (
          <S.SubBenefits className="variant-b">
            <S.SubBenefitsLabel className="variant-b">
              Subscription Plan Includes
            </S.SubBenefitsLabel>

            <S.SubBenefitItem className="variant-b">
              <img src="/images/check-green.svg" alt="item 1" />
              <span>
                <strong>FREE SHIPPING</strong> on every delivery
              </span>
            </S.SubBenefitItem>
            <S.SubBenefitItem className="variant-b">
              <img src="/images/check-green.svg" alt="item 1" />
              <span>
                <strong>FREE BEEF JERKY</strong> with first delivery
                <sup>*</sup>
              </span>
            </S.SubBenefitItem>
            <S.SubBenefitItem className="variant-b">
              <img src="/images/check-green.svg" alt="item 1" />
              <span>
                <S.CancelAnytime
                  onClick={() => setShowSubPopup(true)}
                  className="variant-b"
                >
                  <strong>AUTO-RENEWAL WITH EASY CANCEL</strong>
                </S.CancelAnytime>
              </span>
            </S.SubBenefitItem>
          </S.SubBenefits>
        )}
      </S.Row3>
    </>
  );
};

export default BenefitsB;
