import React from 'react';
import * as S from './LockInSelectorV5.styles';
import { CSSTransition } from 'react-transition-group';

const duration = 500;
const onEnter = node => {
  node.style.height = `${node.dataset.height}px`;
};
const onEntering = node => {
  node.style.height = `${node.dataset.height}px`;
};
const onEntered = node => {
  node.style.height = `${node.dataset.height}px`;
};
const onExit = node => {
  node.style.height = `0px`;
};
const onExiting = node => {
  node.style.height = `0px`;
};
const onExited = node => {
  node.style.height = `0px`;
};

const BenefitsA = ({
  isActive,
  currentCategory,
  elementHeight,
  refs,
  index,
  periodLength,
  setShowSubPopup,
  ...item
}) => {
  return (
    <CSSTransition
      in={isActive}
      timeout={duration}
      onEnter={onEnter}
      onEntering={onEntering}
      onEntered={onEntered}
      onExit={onExit}
      onExiting={onExiting}
      onExited={onExited}
    >
      <>
        {'onetime' === currentCategory && (
          <S.Row2
            style={{
              height: isActive ? `${elementHeight}px` : `0px`
            }}
          >
            <S.SubBenefits className="onetime">
              <S.SubBenefitItem>
                <img src="/images/check-green.svg" alt="item 1" />
                <span>
                  <strong>1 TUB</strong> billed & shipped once
                </span>
              </S.SubBenefitItem>
            </S.SubBenefits>
          </S.Row2>
        )}

        <S.Row2
          ref={refs.current[index]}
          className={`${item.showSubcriptionBenefits ? `` : `hidden`} ${
            isActive ? 'active' : ''
          }`}
          data-height={elementHeight}
          style={{
            height: isActive ? `${elementHeight}px` : `0px`
          }}
        >
          {'subscriptions' === currentCategory && (
            <S.SubBenefits>
              <S.SubBenefitsLabel>
                Subscription Plan Includes
              </S.SubBenefitsLabel>
              {periodLength > 1 ? (
                <S.SubBenefitItem>
                  <img src="/images/check-green.svg" alt="item 1" />
                  <span>
                    <strong>3 TUBS</strong> billed and shipped every 3 months
                  </span>
                </S.SubBenefitItem>
              ) : (
                <S.SubBenefitItem>
                  <img src="/images/check-green.svg" alt="item 1" />
                  <span>
                    <strong>1 TUB</strong> billed & shipped every month
                  </span>
                </S.SubBenefitItem>
              )}
              <S.SubBenefitItem>
                <img src="/images/check-green.svg" alt="item 1" />
                <span>
                  <strong>FREE SHIPPING</strong> on every delivery
                </span>
              </S.SubBenefitItem>
              <S.SubBenefitItem>
                <img src="/images/check-green.svg" alt="item 1" />
                <span>
                  <strong>FREE BEEF JERKY</strong> with first delivery
                  <sup>*</sup>
                </span>
              </S.SubBenefitItem>
              <S.SubBenefitItem>
                <img src="/images/check-green.svg" alt="item 1" />
                <span>
                  <S.CancelAnytime onClick={() => setShowSubPopup(true)}>
                    <strong>AUTO-RENEWAL WITH EASY CANCEL</strong>
                  </S.CancelAnytime>
                </span>
              </S.SubBenefitItem>
            </S.SubBenefits>
          )}
        </S.Row2>
      </>
    </CSSTransition>
  );
};

export default BenefitsA;
