import { useContext } from 'react';
import FunnelContext from '../context/FunnelContext';

const useProductData = () => {
  const {
    currentTubIndex,
    subscription,
    onetime,
    tag: productSize
  } = useContext(FunnelContext);

  return ({
    category = 'subscriptions',
    filterByTag = false,
    filterBySize = null
  }) => {
    let products =
      category === 'subscriptions' ? subscription.products : onetime.products;
    let productData = products.map((p, index) => ({ ...p, index }));

    if (filterBySize) {
      productData = productData?.filter(({ tags }) => {
        return tags.includes(productSize);
      });
    }

    if (filterByTag) {
      productData = productData?.filter(({ tags }) => {
        return tags.includes(filterByTag);
      });
    }

    return productData.at(currentTubIndex);
  };
};

export default useProductData;
