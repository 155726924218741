import React, { useCallback, useContext, useEffect, useState } from 'react';
import * as S from './LockInSelectorV5.styles';
import FunnelContext from '../../../context/FunnelContext';
import { ProductPrice } from '../../../utils/productPrice';
import loadable from '@loadable/component';
import { useRef } from 'react';
import { createRef } from 'react';
import { lockIntemplateText } from '../../../utils/lockInHelpers';
import useProductData from '../../../hooks/useProductData';
import BenefitsA from './BenefitsA';
import BenefitsB from './BenefitsB';

const SubPopup = loadable(() =>
  import('../../PackageSelector/SubPopupLegacy/SubPopupLegacy')
);

const CategoryTabs = loadable(() => import('./CategoryTabs'));

const LockInSelectorV5 = props => {
  const {
    variant = 'A',
    styles = 'default',
    periods,
    filterBySize = false,
    defaultFilterBySize = 'small',
    faqUrl = '#test',
    initialDiscountRate = 20
  } = props;

  const {
    extraObjects,
    setExtraObjects,
    currentCategory,
    setCurrentCategory,
    currentProduct,
    setCurrentProduct,
    currency,
    setTubIndexChecked,
    currentTubIndex,
    setBillingFrequency,
    setFrequency,
    tag: productSize,
    setTag: setProductSize
  } = useContext(FunnelContext);

  const refs = useRef(periods.map(_ => createRef()));
  const currencySymbol = currency?.symbol || `$`;
  const [currentPeriod, setCurrentPeriod] = useState('1-month');
  const [init, setInit] = useState(false);
  const [showSubPopup, setShowSubPopup] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [discountRate, setDiscountRate] = useState(initialDiscountRate);

  const ProductData = useProductData();

  const handleClick = useCallback(
    selected => {
      const {
        value = '1-month',
        billingFrequency = '1:month',
        deliveryFrequency = '1:month',
        category = 'subscriptions',
        productData,
        overrideBumpOfferCategory = false,
        bumpOfferCategory = 'subscriptions',
        discountRate = 20,
        overrideSubDiscountPercentage = false
      } = selected;

      const productDataIndex = productData?.index || 0;

      setDiscountRate(discountRate);
      setCurrentPeriod(value);
      setBillingFrequency(billingFrequency);
      setFrequency(deliveryFrequency);
      setCurrentCategory(category);
      setTubIndexChecked(true);
      setExtraObjects(
        Object.assign(extraObjects, {
          sub_lock_in_enabled: true,
          sub_lock_in_schedule: value,
          tub_index_override: productDataIndex,
          overrideBumpOfferCategory,
          bumpOfferCategory,
          overrideSubDiscountPercentage: overrideSubDiscountPercentage
            ? discountRate
            : null
        })
      );
      setCurrentProduct(
        `${
          category === 'subscriptions' ? 'subscription' : 'onetime'
        }_${currentTubIndex}`
      );
    },
    [
      extraObjects,
      setExtraObjects,
      setCurrentPeriod,
      setTubIndexChecked,
      setCurrentCategory,
      setCurrentProduct,
      currentTubIndex,
      setBillingFrequency,
      setFrequency
    ]
  );

  useEffect(() => {
    if (!init) {
      setInit(true);
      setProductSize(defaultFilterBySize);

      setCurrentPeriod(periods[0]?.value ?? '1-month');

      periods
        .filter(period => period.category === currentCategory)
        .forEach((item, index) => {
          if (item.selected) {
            handleClick(item);
            setActiveIndex(index);
          }
        });

      const productData = ProductData({
        category: currentCategory,
        filterByTag: currentPeriod,
        filterBySize
      });
      if (productData) {
        setTubIndexChecked(true);
        setExtraObjects(
          Object.assign(extraObjects, {
            tub_index_override: productData.index
          })
        );
      }
    }
  }, [
    init,
    setInit,
    periods,
    extraObjects,
    setExtraObjects,
    setCurrentPeriod,
    currentCategory,
    currentProduct,
    currentPeriod,
    ProductData,
    setTubIndexChecked,
    setCurrentCategory,
    setCurrentProduct,
    currentTubIndex,
    setBillingFrequency,
    setProductSize,
    defaultFilterBySize,
    handleClick,
    filterBySize,
    setDiscountRate
  ]);

  const categoryTabsCallback = cat => {
    setActiveIndex(0);
    const items = periods.filter(period => period.category === cat);
    handleClick(items.at(0));
  };

  return (
    <S.Wrapper className={styles}>
      <CategoryTabs
        callback={categoryTabsCallback}
        discountRate={discountRate}
      />
      <S.Container className={`${currentCategory} ${styles}`}>
        <S.ListContainer>
          {periods
            .filter(period => period.category === currentCategory)
            .map((item, index) => {
              const {
                isMathFloorPerMonth,
                hasBorder,
                category = 'subscriptions',
                chewsPerTub = 30,
                value: lockin = '1-month',
                periodLength = 1,
                showMostPopularTag = false,
                showBestValueTag = false,
                titleDiscountBgColor = '#d73375',
                titleDiscountBgHoverColor = '#d73375'
              } = item;

              const productId = `${
                category === 'subscriptions' ? 'subscription' : 'onetime'
              }_${currentTubIndex}`;

              const isActive = activeIndex === index;

              const applyTemplate = text =>
                lockIntemplateText({
                  text,
                  chewsPerTub,
                  lockin,
                  periodLength,
                  category,
                  productId,
                  isMathFloorPerMonth,
                  filterBySize,
                  productSize,
                  currencySymbol
                });

              item.productData = ProductPrice({
                type: `data`,
                currentCategory: category,
                currentProduct: productId,
                filterByTag: lockin
              });

              return (
                <S.ListItem
                  onClick={() => {
                    handleClick(item);
                    setActiveIndex(index);
                  }}
                  key={index}
                  className={`${isActive ? 'active' : ''} ${
                    hasBorder ? `has-border` : ``
                  } ${styles}`}
                  ref={refs[index]}
                >
                  <S.Row>
                    <S.InputRadio
                      className={`${isActive ? 'active' : ''}`}
                    ></S.InputRadio>
                    <S.Left className="title">
                      <div className="bold line0">{item.title}</div>
                      <S.Line1
                        className={`${isActive ? 'active' : ''}`}
                        dangerouslySetInnerHTML={{
                          __html: applyTemplate(item.line1)
                        }}
                      />
                      {!isActive && variant === 'A' && (
                        <S.Line2
                          className={`${isActive ? 'active' : ''}`}
                          dangerouslySetInnerHTML={{
                            __html: applyTemplate(item.line2)
                          }}
                        />
                      )}
                    </S.Left>
                    <S.Right>
                      {item.titleDiscount && (
                        <S.TitleDiscount
                          bgColor={titleDiscountBgColor}
                          bgHoverColor={titleDiscountBgHoverColor}
                          className={`discount ${
                            isActive ? 'active' : ''
                          } ${category}`}
                        >
                          <div>{item.titleDiscount}</div>
                        </S.TitleDiscount>
                      )}
                      {showMostPopularTag && (
                        <>
                          <S.MostPopularTag>Most Popular</S.MostPopularTag>
                        </>
                      )}
                      {showBestValueTag && (
                        <>
                          <S.BestValueTag>Best Value</S.BestValueTag>
                        </>
                      )}
                    </S.Right>
                  </S.Row>

                  {variant === 'A' && (
                    <BenefitsA
                      {...item}
                      {...{
                        refs,
                        index,
                        currentCategory,
                        isActive,
                        setShowSubPopup
                      }}
                    />
                  )}

                  {variant === 'B' && (
                    <S.Row2>
                      <S.Line2
                        className={`variant-b ${isActive ? 'active' : ''}`}
                        dangerouslySetInnerHTML={{
                          __html: applyTemplate(item.line2)
                        }}
                      />
                    </S.Row2>
                  )}
                </S.ListItem>
              );
            })}

          {variant === 'B' && (
            <BenefitsB {...{ currentCategory, setShowSubPopup }} />
          )}
        </S.ListContainer>
        <SubPopup
          show={showSubPopup}
          setShowSubPopup={setShowSubPopup}
          faqURL={faqUrl}
          newWindow={false}
        />
      </S.Container>
    </S.Wrapper>
  );
};

export default LockInSelectorV5;
